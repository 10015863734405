<template>
  <div>
    <user-profile-component-global
      sTextDetailHeader="Detalle del administrador"
      :sBackHref="sBackHref"
      :bWithPathOrNameRoute="true"
      :bAdminPermission="bAdminAdministrator"
      :itemsBreadcrumbs="itemsBreadcrumbs"
    />
  </div>
</template>

<script>
export default {
  name: "AdministratorsDetail",
  data() {
    return {
      sBackHref: "",
      itemsBreadcrumbs: [],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Administradores",
          disabled: false,
          to: { name: "Administrators" },
        },
        {
          text: "Detalle de un administrador",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "" },
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      this.sBackHref = `/home/administrators`;
    },
  },
  computed: {
    bShowAdministrator() {
      return this.$store.state.bShowAdministrator;
    },
    bAdminAdministrator() {
      return this.$store.state.bAdminAdministrator;
    },
  },
  watch: {},
};
</script>

<style scoped>
</style>
